import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import {isAuthenticated} from  "views/auth_utils"

const PrivateRoute = ({  Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
        <Component {...props} />
    }
  />
);


const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <ThemeEditorProvider>
        <BrowserRouter>
          <Switch>
            <Route
              path="/auth"
              render={(props) =>
                isAuthenticated() ? (
                  <Redirect to="/admin" />
                ) : (
                  <AuthLayout {...props} />
                )
              }
            />
            <PrivateRoute
              path="/admin"
              component={AdminLayout}
            />
            <Route render={() => <Redirect to="/auth" />} />
          </Switch>
        </BrowserRouter>
      </ThemeEditorProvider>
    </ChakraProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
