import {Box,Icon,Select,SimpleGrid,useColorModeValue} from "@chakra-ui/react";
import {MdAttachMoney, MdCreditCard, MdReceipt,  MdList, MdLocalMall, MdInsights, MdWallet, MdMonetizationOn, MdPeople} from "react-icons/md";
import React, {useEffect, useState} from "react";
import { Redirect, Route } from "react-router-dom";

import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";

import TransactionsCountDisribution from "views/admin/default/components/TransactionsCountDisribution";
import TransactionsValueDisribution from "views/admin/default/components/TransactionsValueDisribution";
import PieCard from "views/admin/default/components/PieCard";
import DonutCard from "views/admin/default/components/DonutCard"

import {seperateTxnsToIntervals, mapPieStats, mapNumericalStats, mapDeviceDateForDisplay, seperateTxntoHoursIntervals} from "views/admin/default/utils/DataUtils";
import {isAuthenticated, getDeviceAppId} from "views/auth_utils"

const SERVER_URL = "https://auradashapi.xyz"
const SERVER_TXNS_PATH = "get_txns"
const SERVER_WALLETS_PATH = "get_connected_wallets"


export default function UserReports() {
 
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  // Numerical reports states
  const [totalRevenueData, setTotalRevenueData] = useState(null)
  const [totalRevenueInterval, setTotalRevenueInterval] = useState("all")

  const [numberOfUniquePayersData, setNumberOfUniquePayersData] = useState(null)
  const [numberOfUniquePayersInterval, setNumberOfUniquePayersInterval] = useState("all")

  const [avgTxnPriceData, setAvgTxnPriceData] = useState(null)
  const [avgTxnPriceInterval, setAvgTxnPriceInterval] = useState("all")

  const [totalTxnsData, setTotalTxnsData] = useState(null)
  const [totalTxnsInterval, setTotalTxnsInterval] = useState("all")

  // Pie/Donut reports states
  const [networksDistributionData, setNetworksDistributionData] = useState(null)
  const [tokensDistributionData, setTokensDistributionData] = useState(null)
  const [deviceDistributionData, setDeviceDistributionData] = useState(null)

  // Histograms reports states
  const [txnsCountsDistributionData, setTxnsCountsDistributionData] = useState(null)
  const [txnsValuesDistributionData, setTxnsValuesDistributionData] = useState(null)
  const [totalConnectedWalletsIntervals, setTotalConnectedWalletsIntervals] = useState("all")
  const [conversionRateInterval, setConversionRateInterval] = useState("all")
  const [conversionRateData, setConversionRateData] = useState([])
  const [connectedWalletsData, setConnectedWalletsData] = useState([])

  useEffect(() => {
    // This function handles the data fetching from server.
    const fetchData = async () => {
        if (!isAuthenticated()) {
          return
        }
        var app_id = getDeviceAppId()
        let lengthsObject
        try {
          const response_wallets = await fetch(`${SERVER_URL}/${SERVER_WALLETS_PATH}?app_id=${app_id}`, {
            method: 'GET',  
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            }});
            const wallets_res = await response_wallets.json()
            // console.log(`txns: ${JSON.stringify(wallets_res, null, 2)}`);
            const seperatedWalletConnections = seperateTxnsToIntervals(wallets_res)
            lengthsObject = Object.keys(seperatedWalletConnections).reduce((acc, key) => {
              acc[key] = seperatedWalletConnections[key].length;
              return acc;
            }, {});

            setConnectedWalletsData(lengthsObject)
        } catch (err) {
          console.log(err)

        }
        try {
          const response = await fetch(`${SERVER_URL}/${SERVER_TXNS_PATH}?app_id=${app_id}`, {
              method: 'GET',  
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json'
              },
          });
          const res = await response.json()
          const filteredData = res.filter(item => !item.hasOwnProperty('txn_erc20_error'));
          console.log("filteredData")
          // Set data. If testing internally take only last 150 txns to avoid mapping errors.
          const last_txns = filteredData
          const txnsDataSeperated = seperateTxnsToIntervals(last_txns)
          // Mapping numerical stats data
          const numericalStatsData = mapNumericalStats(txnsDataSeperated)
          setNumberOfUniquePayersData(numericalStatsData["uniquePayers"])
          setTotalTxnsData(numericalStatsData["totalTransactions"])

          // Calculate conversion rates
          const conversionRates = Object.keys(lengthsObject).reduce((acc, key) => {
            const connections = lengthsObject[key];
            const transactions = numericalStatsData["totalTransactions"][key];
            
            // Avoid division by zero
            acc[key] = connections > 0 
                  ? `${((transactions / connections) * 100).toFixed(2)}%` 
                  : "0%";
            
            return acc;
          }, {});
          console.log(conversionRates)
          setConversionRateData(conversionRates)

          // Mapping Pie/Donut charts data
          setNetworksDistributionData(mapPieStats(txnsDataSeperated, "chain_name", "Network"))
          setTokensDistributionData(mapPieStats(txnsDataSeperated, "token_symbol", "Token"))
          setDeviceDistributionData(mapDeviceDateForDisplay(txnsDataSeperated))
          // Mapping Histograms data
          setTxnsCountsDistributionData(seperateTxntoHoursIntervals(txnsDataSeperated))
          // setTxnsValuesDistributionData(getTransactionsValueDistributionData(txnsDataSeperated))


        } catch (err) {
          console.log(err)
        }
    }
    fetchData()
    // eslint-disable-next-line
 }, [])
  return (
    !isAuthenticated() ? <Route render={() => <Redirect to="/auth" />} /> :
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
        gap='20px'
        mb='20px'
      >
        {/* Mapping numerical stats */}
        {[
          {
            data: totalTxnsData,
            interval: totalTxnsInterval,
            setter: setTotalTxnsInterval,
            name: "Total Transactions",
            icon: <Icon w='32px' h='32px' as={MdReceipt} color={brandColor} />,
          },
          {
            data: numberOfUniquePayersData,
            interval: numberOfUniquePayersInterval,
            setter: setNumberOfUniquePayersInterval,
            name: "Unique Payers",
            icon: <Icon w='32px' h='32px' as={MdPeople} color={brandColor} />,
          },
          {
            data: connectedWalletsData,
            interval: totalConnectedWalletsIntervals,
            setter: setTotalConnectedWalletsIntervals,
            name: "Wallet Connections",
            icon: <Icon w='32px' h='32px' as={MdWallet} color={brandColor} />,
          },
          {
            data: conversionRateData,
            interval: conversionRateInterval,
            setter: setConversionRateInterval,
            name: "Conversion Rate",
            icon: <Icon w='32px' h='32px' as={MdInsights} color={brandColor} />,
          },
        ].map((obj, index) => (
          <MiniStatistics
            startContent={
              <IconBox w='56px' h='56px' bg={boxBg} icon={obj.icon} />
            }
            endContent={
              <Select
                fontSize='sm'
                variant='subtle'
                defaultValue='all'
                width='unset'
                fontWeight='400'
                paddingLeft={"15px"}
                onChange={(ev) => obj.setter(ev?.target?.value)}
              >
                <option value='all'>All</option>
                <option value='monthly'>Monthly</option>
                <option value='weekly'>Weekly</option>
                <option value='daily'>Daily</option>
              </Select>
            }
            name={obj.name}
            key={index}
            value={obj.data ? obj.data[obj.interval] : "Loading..."}
          />
        ))}
      </SimpleGrid>


      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        {/* <TotalSpent />  Nice two lines graphic*/}

        <TransactionsCountDisribution data={txnsCountsDistributionData}/>
        {/* <TransactionsValueDisribution data={txnsValuesDistributionData} /> */}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap='20px' mb='20px'>
        {/* Mapping each data type to a PieCard component */}
        {[networksDistributionData, tokensDistributionData].map(
            (dataType,index) => <PieCard key={index} data={dataType || []}/>
        )}
        <DonutCard data={deviceDistributionData}/>
      </SimpleGrid>
    </Box>

    
  );
}
